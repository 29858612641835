import React from "react";

import HorizontalScroller from "components/HorizontalScroller";
import forCreatorsImage from "svg/forCreators.svg";
import Colors from "src/styles/Colors";

const ForCreators: React.FC<{}> = () => {
  return <HorizontalScroller image={forCreatorsImage} border={Colors.yellow} />;
};

export default ForCreators;
