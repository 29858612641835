import React, { useEffect, useRef } from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import gsap from "gsap";

import { Label1, Paragraph1, Title1, Title3 } from "src/components/Text";
import Colors from "src/styles/Colors";

import { FluidImage } from "src/Types";

import { BoxOutlineButton } from "src/components/Buttons";
import { ReactComponent as ArrowSVG } from "svg/arrowRight.svg";
import media from "src/styles/media";

type Props = {
  data: {
    yourPlatform: FluidImage;
  };
};

const Section3: React.FC<Props> = ({ data }) => {
  const title: any = useRef();

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: title.current,
        start: "top center",
      },
    });
    tl.to(
      ".creators__section3-sticker",
      { opacity: 1, scale: 1, ease: "back.out(1.7)" },
      0.3
    );
  }, []);
  return (
    <Wrapper>
      <Title ref={title}>NFTs Need a Home</Title>
      <Sticker className="creators__section3-sticker">
        <Img fluid={data.yourPlatform.childImageSharp.fluid} />
      </Sticker>
      <Label>this is how the metaverse begins</Label>
      <Line />
      <Details>
        <Detail>
          <TitleSecondary>Virtual Worlds</TitleSecondary>
          <Paragraph>
            Let the people flex their NFTs on your block. Digital goods need to
            be supported in multiple worlds like yours. This is how the
            metaverse begins.
          </Paragraph>
        </Detail>
        <Detail>
          <TitleSecondary>Social Apps</TitleSecondary>
          <Paragraph>
            Content shared in your app isn't confined to the limits of physical
            space. Enable your users to bring their digitally own goods into
            your platform.
          </Paragraph>
        </Detail>
        <Detail>
          <TitleSecondary>Bitski Wallet SSO</TitleSecondary>
          <Paragraph>
            Every Bitski user has a secure, OAuth compliant wallet to keep their
            NFTs safe. Allowing users to easily connect their wallets to your
            worlds.
          </Paragraph>
        </Detail>
        <Detail>
          <TitleSecondary>Developer Tools</TitleSecondary>
          <Paragraph>
            Automate distribution and management of NFTs on your platform.
            Bitski developer tools were built so platforms like yours can start
            experimenting with NFTs.
          </Paragraph>
        </Detail>
      </Details>
      <Ownership>Let's proliferate the metaverse.</Ownership>
      <a href="https://docs.bitski.com">
        <Button color="black" arrow>
          Read the Docs
          <Arrow />
        </Button>
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 61vw;
  padding: 2.8vw 0 0 0;
  width: 72.22%;
  margin-left: 2.5vw;
  position: relative;
  overflow: hidden;
  ${media.tablet} {
    height: 352.2vw;
    margin-left: 4.8vw;
    width: 90.3vw;
  }
`;
const Title = styled(Title1)`
  color: ${Colors.darkGrey};

  ${media.tablet} {
    margin-left: 32.6vw;
    font-size: 6.3vw;
  }
`;
const Label = styled(Label1)`
  color: ${Colors.darkGrey};
  line-height: 1.08em;
  letter-spacing: -0.02875em;
  font-size: 1.7vw;
  ${media.tablet} {
    margin-left: 32.6vw;
    font-size: 4.8vw;
  }
`;
const Line = styled.hr`
  width: 65.3vw;
  height: 2px;
  background: ${Colors.darkGrey};
  margin: 1.8vw 0 5.2vw;
  ${media.tablet} {
    width: 100%;
    height: 1px;
    margin: 7.2vw 0 18.4vw;
  }
`;
const Details = styled.div`
  width: 65.3vw;
  display: grid;
  grid-template-columns: repeat(2, 29.3vw);
  grid-column-gap: 6.5vw;
  ${media.tablet} {
    width: 100%;
    display: block;
  }
`;
const Detail = styled.div`
  height: 15.2vw;
  ${media.tablet} {
    height: unset;
    margin-bottom: 12.1vw;
  }
`;
const TitleSecondary = styled(Title3)`
  font-family: "Neue Machina";
  font-weight: normal;
  ${media.tablet} {
    width: 56.3vw;
  }
`;
const Paragraph = styled(Paragraph1)`
  margin-left: 3.3vw;
  color: ${Colors.charcoal};
  margin-top: 0.7vw;
  ${media.tablet} {
    margin-left: 12.3vw;
    margin-top: 2.4vw;
  }
`;
const Ownership = styled(Title3)`
  margin-top: 0.2vw;
  width: 58vw;
  font-weight: normal;
  ${media.tablet} {
    width: 100%;
    font-size: 6.3vw;
  }
`;
const Button = styled(BoxOutlineButton)`
  width: 16vw;
  background: ${Colors.yellow};
  margin-top: 4.2vw;
  ${media.tablet} {
    width: 100%;
    margin-top: 10.9vw;
    justify-content: center;
  }
`;
const Arrow = styled(ArrowSVG)`
  width: 1.7vw;
  ${media.tablet} {
    width: 6vw;
    margin-left: 3vw;
  }
`;

const Sticker = styled.div`
  width: 14.2vw;
  position: absolute;
  top: 0;
  left: 55vw;
  opacity: 0;
  transform: scale(0.1);
  ${media.tablet} {
    display: none;
  }
`;

export default Section3;
