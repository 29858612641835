import React from "react";

import blockchain from "svg/blockchain.svg";
import HorizontalScroller from "components/HorizontalScroller";
import Colors from "src/styles/Colors";

const Blockchain: React.FC<{}> = () => {
  return <HorizontalScroller border={Colors.brightRed} image={blockchain} />;
};

export default Blockchain;
