import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import gsap from "gsap";

import { FluidImage } from "src/Types";
import Colors from "src/styles/Colors";
import { Label1, Paragraph3, Title3 } from "src/components/Text";

import { ReactComponent as ChainSVG } from "svg/chain.svg";
import { ReactComponent as KeySVG } from "svg/key.svg";
import { ReactComponent as SwordSVG } from "svg/sword.svg";
import mouse from "svg/darkCursor.svg";

import media from "src/styles/media";

type Props = {
  data: {
    goldCoin: FluidImage;
  };
};

const HowItWorks: React.FC<Props> = ({ data }) => {
  const section: any = useRef();
  const coin1: any = useRef();
  const coin2: any = useRef();
  const coin3: any = useRef();

  useEffect(() => {
    if (window.innerWidth > 1024) {
      gsap.to(coin1.current, {
        yPercent: 90,
        ease: "none",
        scrollTrigger: {
          trigger: section.current,
          scrub: true,
        },
      });
      gsap.to(coin2.current, {
        yPercent: -50,
        xPercent: 20,
        rotate: -100,
        ease: "none",
        scrollTrigger: {
          trigger: section.current,
          scrub: true,
        },
      });
      gsap.to(coin3.current, {
        yPercent: 170,
        rotate: 190,
        ease: "none",
        scrollTrigger: {
          trigger: section.current,
          scrub: true,
        },
      });
    } else {
      gsap.to(coin1.current, {
        yPercent: 30,
        ease: "none",
        scrollTrigger: {
          trigger: coin1.current,
          scrub: true,
        },
      });
      gsap.to(coin2.current, {
        yPercent: 100,
        rotate: 100,
        ease: "none",
        scrollTrigger: {
          trigger: coin2.current,
          scrub: true,
        },
      });
      gsap.to(coin3.current, {
        yPercent: 120,
        rotate: 190,
        ease: "none",
        scrollTrigger: {
          trigger: section.current,
          scrub: true,
        },
      });
    }
  }, []);

  return (
    <Wrapper ref={section}>
      <LabelWrapper>
        <Label>how it works</Label>
      </LabelWrapper>
      <Details>
        <Detail>
          <Chain />
          <Title>
            BLOCKCHAIN <br /> SIMPLIFIED
          </Title>
          <Paragraph>
            At it's simplest form, a blockchain is like a global spreadsheet of
            transactions. Nobody can edit existing transactions, but anyone can
            add a new one
          </Paragraph>
        </Detail>
        <Detail>
          <Key />
          <Title>
            THE INTERNET’S
            <br /> LAW OF OWNERSHIP
          </Title>
          <Paragraph>
            Turns out, blockchain is a great tool for verifying the owner of
            digital things. Which means the whole concept of owning something
            digitally can exist. Which is crazy and mind bending, but here we
            are.
          </Paragraph>
        </Detail>
        <Detail>
          <Sword />
          <Title>
            A BRAVE NEW <br /> FRONTIER
          </Title>
          <Paragraph>
            We're true believers that digital ownership will have profound
            impacts on how we use the internet, build communities, and reconcile
            our value to existing platforms. There's an entire new world of
            creation, ownership and monetization coming, and it can be truly
            overwhelming. We're here to help you experiment with ideas today,
            and navigate the revolution.
          </Paragraph>
        </Detail>
      </Details>
      <Coin ref={coin1}>
        <Img fluid={data.goldCoin.childImageSharp.fluid} />
      </Coin>
      <Coin2 ref={coin2}>
        <Img fluid={data.goldCoin.childImageSharp.fluid} />
      </Coin2>
      <Coin3 ref={coin3}>
        <Img fluid={data.goldCoin.childImageSharp.fluid} />
      </Coin3>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 71vw;
  width: 100%;
  max-width: 100%;
  position: relative;
  background: ${Colors.offWhite};
  padding: 2.7vw 6.9vw;
  cursor: url(${mouse}), auto;

  ${media.tablet} {
    height: 310vw;
    padding: 10.9vw 4.8vw;
  }
`;
const LabelWrapper = styled.div`
  width: 12.2vw;
  height: 3.2vw;
  border: solid 1px ${Colors.brightRed};
  border-radius: 3.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.tablet} {
    height: 8vw;
    width: 32.1vw;
    border-radius: 24.1vw;
  }
`;
const Label = styled(Label1)`
  color: ${Colors.brightRed};
  font-size: 2vw;
  line-height: 1.0666666666666667em;
  letter-spacing: -0.043125em;
  text-align: center;
  ${media.tablet} {
    font-size: 4.8vw;
  }
`;
const Details = styled.div`
  width: 61.6vw;
  margin-left: 17.6vw;
  margin-top: 3vw;
  ${media.tablet} {
    width: 100%;
    margin: 15.4vw 0 0;
  }
`;
const Detail = styled.div`
  width: 36.1vw;
  &:nth-child(2) {
    margin-left: 25vw;
  }
  ${media.tablet} {
    width: 100%;
    margin-bottom: 36.2vw;
    &:nth-child(2) {
      margin-left: 0;
    }
  }
`;
const Title = styled(Title3)`
  font-family: "Neue Machina";
  font-weight: normal;
  ${media.tablet} {
    width: 61.5vw;
  }
`;
const Paragraph = styled(Paragraph3)`
  font-family: "Neue Machina";
  padding-left: 3.5vw;
  color: ${Colors.charcoal};
  margin-top: 0.7vw;
  ${media.tablet} {
    font-size: 3.8vw;
    margin-top: 2.4vw;
  }
`;
const Chain = styled(ChainSVG)`
  width: 1.7vw;
  margin-bottom: 0.9vw;
  ${media.tablet} {
    width: 5.8vw;
  }
`;
const Key = styled(KeySVG)`
  width: 1.1vw;
  margin-top: 3.5vw;
  margin-bottom: 0.2vw;
  ${media.tablet} {
    width: 4vw;
  }
`;
const Sword = styled(SwordSVG)`
  width: 2.3vw;
  margin-top: 1.9vw;
  margin-bottom: 0.7vw;
  ${media.tablet} {
    width: 5.8vw;
  }
`;
const Coin = styled.div`
  position: absolute;
  width: 11.7vw;
  top: -4.3vw;
  left: 71.1vw;
  ${media.tablet} {
    width: 34.5vw;
    top: -12.1vw;
  }
`;
const Coin2 = styled.div`
  position: absolute;
  width: 10vw;
  top: 7.8vw;
  left: 81.5vw;
  transform: rotate(-15.5deg);
  ${media.tablet} {
    width: 19vw;
    top: 79.8vw;
    left: -6.7vw;
  }
`;
const Coin3 = styled.div`
  position: absolute;
  width: 10vw;
  left: 5.2vw;
  top: 22.1vw;
  transform: rotate(30.64deg);
  ${media.tablet} {
    width: 39.4vw;
    top: 163vw;
    left: 68.8vw;
  }
`;
export default HowItWorks;
