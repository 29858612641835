import React from "react";
import { graphql } from "gatsby";

import Layout from "components/layout";
import SEO from "components/seo";

import Hero from "src/sections/home/Hero";
import ForUsers from "src/sections/home/ForUsers";
import UserWallet from "src/sections/home/UserWallet";
import ForCreators from "src/sections/home/ForCreators";
import CreatorTools from "src/sections/home/CreatorTools";
import Manifesto from "src/sections/home/Manifesto";
import InTheWild from "src/sections/home/InTheWild";
import UseCases from "src/sections/home/UseCases";
import YouPlusBitski from "src/sections/home/YouPlusBitski";
import Blockchain from "src/sections/home/Blockchain";
import HowItWorks from "src/sections/home/HowItWorks";
import Faq from "src/sections/home/Faq";

const IndexPage = ({ data }) => (
  <Layout>
    <SEO />
    <Hero data={data} />
    <ForUsers />
    <UserWallet data={data} />
    <ForCreators />
    <CreatorTools data={data} />
    <Manifesto data={data} />
    {/* <InTheWild />
    <UseCases data={data} /> */}
    {/* <YouPlusBitski /> */}
    <Blockchain />
    <HowItWorks data={data} />
    {/* <Faq /> */}
  </Layout>
);

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2800, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    shoe: file(relativePath: { eq: "heroShoe.png" }) {
      ...fluidImage
    }
    holdingCard: file(relativePath: { eq: "holdingCard.png" }) {
      ...fluidImage
    }
    heroTicket: file(relativePath: { eq: "heroTicket.png" }) {
      ...fluidImage
    }
    astronaut: file(relativePath: { eq: "astronaut.png" }) {
      ...fluidImage
    }
    arwing: file(relativePath: { eq: "arwing.png" }) {
      ...fluidImage
    }
    customAir: file(relativePath: { eq: "customAir.png" }) {
      ...fluidImage
    }
    customEmote: file(relativePath: { eq: "customEmote.png" }) {
      ...fluidImage
    }
    dotGrid: file(relativePath: { eq: "dotGrid.png" }) {
      ...fluidImage
    }
    interfacePanel: file(relativePath: { eq: "interfacePanel.png" }) {
      ...fluidImage
    }
    trade: file(relativePath: { eq: "trade.png" }) {
      ...fluidImage
    }
    pizza: file(relativePath: { eq: "pizza.png" }) {
      ...fluidImage
    }
    fruit: file(relativePath: { eq: "fruit.png" }) {
      ...fluidImage
    }
    suhDude: file(relativePath: { eq: "suhDude.png" }) {
      ...fluidImage
    }
    greenCoin: file(relativePath: { eq: "greenCoin.png" }) {
      ...fluidImage
    }
    appCircle: file(relativePath: { eq: "appCircle.png" }) {
      ...fluidImage
    }
    skins: file(relativePath: { eq: "skins.png" }) {
      ...fluidImage
    }
    artDrop: file(relativePath: { eq: "artDrop.png" }) {
      ...fluidImage
    }
    goldenTicket: file(relativePath: { eq: "goldenTicket.png" }) {
      ...fluidImage
    }
    tokenBadge: file(relativePath: { eq: "tokenBadge.png" }) {
      ...fluidImage
    }
    goodIdeas: file(relativePath: { eq: "goodIdeas.png" }) {
      ...fluidImage
    }
    astro1: file(relativePath: { eq: "astro1.png" }) {
      ...fluidImage
    }
    lineGrid: file(relativePath: { eq: "lineGrid.png" }) {
      ...fluidImage
    }
    builtOnBlockchain: file(relativePath: { eq: "builtOnBlockchain.png" }) {
      ...fluidImage
    }
    yourPlatform: file(relativePath: { eq: "yourPlatform.png" }) {
      ...fluidImage
    }
    sunrise: file(relativePath: { eq: "sunrise.jpg" }) {
      ...fluidImage
    }
    rtfktx: file(relativePath: { eq: "rtfktx.jpg" }) {
      ...fluidImage
    }
    sandbox: file(relativePath: { eq: "sandbox.jpg" }) {
      ...fluidImage
    }
    cohort001: file(relativePath: { eq: "cohort001.jpg" }) {
      ...fluidImage
    }
    matcha: file(relativePath: { eq: "matcha.png" }) {
      ...fluidImage
    }
    Opensea: file(relativePath: { eq: "Opensea.jpg" }) {
      ...fluidImage
    }
    banksy: file(relativePath: { eq: "banksy.jpg" }) {
      ...fluidImage
    }
    goldCoin: file(relativePath: { eq: "goldCoin.png" }) {
      ...fluidImage
    }
  }
`;

export default IndexPage;
