import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import { FluidImage } from "src/Types";

import flag from "svg/flag.svg";
import Colors from "src/styles/Colors";

import { Label1, Title3 } from "src/components/Text";
import { BoxOutlineButton } from "src/components/Buttons";
import media from "src/styles/media";

type Props = {
  data: {
    sunrise: FluidImage;
  };
};

const Manifesto: React.FC<Props> = ({ data }) => {
  return (
    <Wrapper>
      <Line />
      <Content>
        <Sun>
          <Img fluid={data.sunrise.childImageSharp.fluid} />
        </Sun>
        <TextContainer>
          <LabelWrapper>
            <Label>our north star</Label>
          </LabelWrapper>
          <Title>
            Bitski exists to increase accesibility, participation and creator
            sovereignty in the internet economy.
            <br />
            {/* <br />
            <br />
            Join us? */}
          </Title>
          {/* <InputWrapper>
            <Input placeholder="your@email.com" />
            <Button color={Colors.blue}>sign me up</Button>
          </InputWrapper> */}
        </TextContainer>
      </Content>
      <Line />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 68.3vw;
  padding: 0 6.9vw;
  background: ${Colors.offWhite};
  flex-wrap: wrap;
  cursor: url(${flag}), auto;
  width: 100vw;
  overflow: hidden;
  max-width: 100%;
  ${media.tablet} {
    padding: 0 4.8vw;
    height: 313.7vw;
  }
`;

const Line = styled.hr`
  width: 86.1vw;
  background: black;
  height: 1px;
  border: none;
  ${media.tablet} {
    width: 100%;
    &:nth-child(3) {
      display: none;
    }
  }
`;
const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 5.8vw;
  margin-bottom: 5.6vw;
  ${media.tablet} {
    display: block;
  }
`;

const Sun = styled.div`
  width: 36.1vw;
  ${media.tablet} {
    width: 100%;
    height: 90.3vw;
    overflow: hidden;
  }
`;
const TextContainer = styled.div`
  width: 43.1vw;
  position: relative;
  ${media.tablet} {
    width: 100%;
  }
`;
const LabelWrapper = styled.div`
  width: 13vw;
  height: 3.2vw;
  border: solid 1px ${Colors.brightRed};
  border-radius: 3.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.3vw;
  margin-bottom: 2.7vw;
  ${media.tablet} {
    height: 8vw;
    width: 32.1vw;
    border-radius: 24.1vw;
    margin-bottom: 8.9vw;
    margin-top: 7.2vw;
  }
`;
const Label = styled(Label1)`
  color: ${Colors.brightRed};
  font-size: 2vw;
  line-height: 1.0666666666666667em;
  letter-spacing: -0.043125em;
  text-align: center;
  ${media.tablet} {
    font-size: 4.8vw;
  }
`;
const Title = styled(Title3)`
  font-weight: normal;
  font-size: 2.7vw;
  text-transform: uppercase;
  margin-bottom: 3.8vw;
  ${media.tablet} {
    font-size: 7.7vw;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${media.tablet} {
    display: block;
  }
`;
const Input = styled.input`
  width: 29.8vw;
  height: 3.5vw;
  background: white;
  padding: 0 1.7vw;
  border: none;
  font-size: 1.2vw;
  ::placeholder {
    color: #c4c4c4;
  }
  ${media.tablet} {
    width: 100%;
    height: 12.1vw;
    font-size: 4.3vw;
    padding 0 6vw;
  }
`;
const Button = styled(BoxOutlineButton)`
  width: 11.9vw;
  background: transparent;
  justify-content: center;
  ${media.tablet} {
    width: 100%;
    margin-top: 7.2vw;
  }
`;

export default Manifesto;
